import React from 'react';
import './CubeSpinner.scss';

interface CubeSpinnerProps {
    children?: React.ReactNode;
}

//DEPRECATED
const CubeSpinner: React.FC<CubeSpinnerProps> = (props: CubeSpinnerProps) => {
    return (
        <div className="cube-spinner-shadow-container">
            <div className="cube-spinner-container">
                <div className="cube-spinner">
                    <div className="cube-spinner__item cube-spinner__front">
                        {props.children[0]}
                    </div>

                    <div className="cube-spinner__item cube-spinner__back">
                        {props.children[1]}
                    </div>

                    <div className="cube-spinner__item cube-spinner__top"></div>

                    <div className="cube-spinner__item cube-spinner__bottom"></div>

                    <div className="cube-spinner__item cube-spinner__left">
                        {props.children[2]}
                    </div>

                    <div className="cube-spinner__item cube-spinner__right">
                        {props.children[3]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CubeSpinner;
