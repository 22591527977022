import cn from 'classnames';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Direction } from '../../../../globals';
import Card from '../Card';
import './StartHereVerticalCard.scss';

interface StartHereVerticalCardProps {
    bookCourse: BookCourse;
    className?: string;
}

const StartHereVerticalCard: React.FC<StartHereVerticalCardProps> = (
    props: StartHereVerticalCardProps
) => {
    return (
        <Card
            direction={Direction.vertical}
            className={cn(props.className, 'starthere-vertical-card')}
            aos="zoom-in"
        >
            <Img
                className="starthere-vertical-card__image"
                fluid={props.bookCourse.image.childImageSharp.fluid}
                alt={props.bookCourse.title}
            ></Img>

            <h3 className="starthere-vertical-card__title">
                {props.bookCourse.title}
            </h3>
            <p className="starthere-vertical-card__description">
                {props.bookCourse.description}{' '}
                <Link
                    className="starthere-vertical-card__seemore"
                    to={props.bookCourse.url}
                >
                    Learn more
                </Link>
            </p>
        </Card>
    );
};

export default StartHereVerticalCard;
