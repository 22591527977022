import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import AnnouncementHeader from '../../components/ui/AnnouncementHeader/AnnouncementHeader';
import StartHereCard from '../../components/ui/Cards/StartHereCard/StartHereCard';
import StartHereVerticalCard from '../../components/ui/Cards/StartHereVerticalCard/StartHereVerticalCard';
import CubeSpinner from '../../components/ui/CubeSpinner/CubeSpinner';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import { PostsSelector } from '../../components/utils/PostsSelector';
import Seo from '../../components/utils/Seo';
import { TopicsDetailsSelector } from '../../components/utils/TopicDetailsSelector';
import { useAnimationOnScroll } from '../../components/utils/useAnimateOnScroll';
import './start-here.scss';

interface StartHereProps {
    data: {
        startHereYaml: {
            sections: StartHereBlock[];
            spinnerImages: FluidImage[];
            booksCourses: BookCourse[];
        };
        topicsYaml: {
            topics: TopicDetails[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
    };
}

const StartHere: React.FC<StartHereProps> = ({ data }: StartHereProps) => {
    const postsSelector = new PostsSelector(data.allMdx.nodes);
    const topicsDetailsSelector = new TopicsDetailsSelector(
        data.topicsYaml.topics
    );
    useAnimationOnScroll();
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Start Here"
                url="start-here/"
                description="How to started to learn Deep Learning with AI Summer"
            />
            <AnnouncementHeader />

            <PageBanner className="start-here-banner">
                <Fragment>
                    <h1>Everything you need to know about Deep Learning</h1>
                    <p>
                        At AI Summer you can learn everything about Deep
                        Learning from scratch. Start with fundamental concepts
                        and basic architectures, continue with advanced topics
                        and real-life applications, and finally deploy your
                        model to the world
                    </p>
                </Fragment>
                <Fragment>
                    <CubeSpinner>
                        {data.startHereYaml.spinnerImages.map((s) => {
                            return (
                                <Img
                                    key={s.childImageSharp.fluid.src}
                                    fluid={s.childImageSharp.fluid}
                                ></Img>
                            );
                        })}
                    </CubeSpinner>
                </Fragment>
            </PageBanner>

            {data.startHereYaml.sections.map((s) => {
                return (
                    <PageSection className="start-here-section" key={s.title}>
                        <Container>
                            <div className="section-text">
                                <h2>{s.title}</h2>
                            </div>
                            <StartHereCard
                                title={s.subtitle}
                                image={s.image}
                                url={s.url}
                                posts={postsSelector.filter(s.posts)}
                                topics={topicsDetailsSelector.filter(s.topics)}
                            />
                        </Container>
                    </PageSection>
                );
            })}

            <PageSection className="start-here-section">
                <Container>
                    <div className="section-text">
                        <h2>Discover our books and courses</h2>
                        <h4>
                            If you prefer a more structured approach with
                            minimal external links, you can refer to our courses
                            and books.
                        </h4>
                    </div>
                    <Group size={3}>
                        <StartHereVerticalCard
                            bookCourse={data.startHereYaml.booksCourses[0]}
                        />
                        <StartHereVerticalCard
                            bookCourse={data.startHereYaml.booksCourses[1]}
                        />
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const fundamentalsQuery = graphql`
    {
        startHereYaml {
            sections {
                posts
                title
                subtitle
                url
                topics
                image {
                    childImageSharp {
                        fluid {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
            spinnerImages {
                childImageSharp {
                    fluid {
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }
            booksCourses {
                title
                description
                url
                image {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
            }
        }

        topicsYaml {
            topics {
                desc
                title
                url
            }
        }
        allMdx(filter: { frontmatter: { layout: { eq: "BlogPage" } } }) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                base64
                                aspectRatio
                                src
                                srcSet
                                sizes
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
    }
`;

export default StartHere;
