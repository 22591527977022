import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Direction } from '../../../../globals';
import Card from '../Card';
import './StartHereCard.scss';

interface StartHereCardProps {
    title: string;
    image: FluidImage;
    url: string;
    posts: Record<string, Frontmatter>;
    topics: Record<string, TopicDetails>;
}

const StartHereCard: React.FC<StartHereCardProps> = (
    props: StartHereCardProps
) => {
    return (
        <Card
            direction={Direction.horizontal}
            className="starthere-card"
            aos="zoom-in"
        >
            <div className="starthere-card__identity">
                <h2
                    className={`starthere-card__identity__title--${props.title.replace(
                        /\s+/g,
                        ''
                    )}`}
                >
                    {props.title}
                </h2>
                <Img
                    className="starthere-card__identity__image"
                    fluid={props.image.childImageSharp.fluid}
                    alt={props.title}
                ></Img>
            </div>
            <div className="starthere-card__description">
                <h4
                    className={`starthere-card__description__title--${props.title.replace(
                        /\s+/g,
                        ''
                    )}`}
                >
                    Topics
                </h4>
                <ul>
                    {Object.keys(props.topics).map((t) => (
                        <li key={t}>
                            <Link to={`/topics/${props.topics[t].url}`}>
                                {props.topics[t].title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="starthere-card__resources">
                <h4
                    className={`starthere-card__description__title--${props.title.replace(
                        /\s+/g,
                        ''
                    )}`}
                >
                    Example articles
                </h4>
                {Object.keys(props.posts).map((p) => (
                    <div
                        key={p}
                        className="starthere-card__resources__resource"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M10 17l5-5-5-5v10z" />
                        </svg>
                        <Link to={`/${props.posts[p].suburl}`}>
                            {props.posts[p].title}
                        </Link>
                    </div>
                ))}
                <Link className="starthere-card__resources__seemore" to={props.url}>See more</Link>
            </div>
        </Card>
    );
};

export default StartHereCard;
